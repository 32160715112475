import "./services-summary.css";
import React from "react";

export interface ServicesSummaryProps {
  className?: string;
}

export const ServicesSummary: React.FC<ServicesSummaryProps> = ({
  className = "",
}) => (
  <div className={`${className} ServicesSummary_div1`}>
    Services Summary
    <div className="ServicesSummary_div2 ServiceSummaryTitle">
      Professional Branding
      <p className="ServicesSummary_p1">
        Build a strong and recognizable personal brand with our expert guidance.
      </p>
    </div>
    <div className="ServicesSummary_div2 ServiceSummaryTitle">
      Content Production
      <p className="ServicesSummary_p1">
        Quality matters, and we&apos;ve got you covered. Experience rules out
        here.
      </p>
    </div>
    <div className="ServicesSummary_div2 ServiceSummaryTitle">
      Career Management
      <p className="ServicesSummary_p1">
        Receive personalized guidance to navigate and grow your career in the
        industry.
      </p>
    </div>
    <div className="ServicesSummary_div2 ServiceSummaryTitle">
      Marketing &amp; Promotion
      <p className="ServicesSummary_p1">
        Boost your visibility with targeted promotional strategies tailored to
        your audience.
      </p>
    </div>
    <div className="ServicesSummary_div2 ServiceSummaryTitle">
      Support &amp; Well-being
      <p className="ServicesSummary_p1">
        Taking care of yourself is important. We offer resources for your mental
        and physical well-being.
      </p>
    </div>
    <div className="ServicesSummary_div2 ServiceSummaryTitle">
      And More!
      <p className="ServicesSummary_p1">
        At Vicarious Studio, we are a full-service adult talent agency dedicated
        to providing comprehensive support, branding, and career management for
        our models.
      </p>
    </div>
  </div>
);
