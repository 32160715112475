import "./hero.css";
import React from "react";
import M9Webp from "../../assets/images/models/m9.webp";
import M11Webp from "../../assets/images/models/m11.webp";
import M8Webp from "../../assets/images/models/m8.webp";
import M10Webp from "../../assets/images/models/m10.webp";

export interface HeroProps {
  className?: string;
}

export const Hero: React.FC<HeroProps> = ({ className = "" }) => (
  <div className={`${className} HeroMain`}>
    <div className="Hero_div1">
      <div className="Hero_div6">★ ★ ★ </div>
      <div className="Hero_div3">Empowering Talent</div>
      <div className="Hero_div5"> ★ ★ ★ </div>
    </div>
    <div className="Hero_div2">
      <img src={M9Webp} alt="" className="Hero_img1" />
      <img src={M11Webp} alt="" className="Hero_img2" />
      <img src={M8Webp} alt="" className="Hero_img3" />
      <img src={M10Webp} alt="" className="Hero_img4" />
    </div>
    <div className="Hero_div1">
      <div className="Hero_div7">★ ★ ★ </div>
      <div className="Hero_div4">Redefining Success</div>
      <div className="Hero_div8"> ★ ★ ★ </div>
    </div>
  </div>
);
