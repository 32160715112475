export const getFireErrorMessage = (error) => {
  switch (error.code) {
    case 'auth/email-already-in-use':
      return 'The email address is already in use. Please use a different email.';
    case 'auth/invalid-email':
      return 'The email address is not valid. Please enter a valid email.';
    case 'auth/weak-password':
      return 'The password is too weak. Please enter a stronger password.';
    case 'auth/user-not-found':
      return 'No user found with this email. Please check and try again.';
    case 'auth/wrong-password':
      return 'Incorrect password. Please try again.';
    case 'auth/too-many-requests':
      return 'Too many unsuccessful login attempts. Please try again later.';
    case 'auth/network-request-failed':
      return 'Network error. Please check your internet connection and try again.';
    case 'auth/internal-error':
      return 'An internal error occurred. Please try again later.';
    case 'auth/operation-not-allowed':
      return 'Sign-in is currently disabled. Please contact support.';
    case 'auth/requires-recent-login':
      return 'Please log in again to continue. Your session has expired.';
    case 'auth/invalid-credential':
      return 'Invalid credentials. Please check your email and password and try again.';
    // Add more cases as needed for other Firebase Auth errors
    default:
      return 'An unexpected error occurred. Please try again.';
  }
};
