import "./testimonial.css";
import React from "react";
import M12Webp from "../../assets/images/models/m12.webp";

export interface TestimonialProps {
  className?: string;
}

export const Testimonial: React.FC<TestimonialProps> = ({ className = "" }) => (
  <div className={`${className} Testimonial_div1`}>
    <div className="Testimonial_div2">
      <img src={M12Webp} alt="" className="Testimonial_img1" />
    </div>
    <div className="Testimonial_div3">
      <p>
        &quot;Joining Vicarious Studio was the best decision I made. As an
        independent model, I struggled with branding, content creation, and
        finding the right opportunities. With Vicarious Studio&apos;s
        full-service support, I&apos;ve not only enhanced my personal brand but
        also significantly increased my earnings. The team is incredibly
        supportive, and the resources they provide have made my work so much
        easier and more rewarding. I feel valued and empowered every step of the
        way!&quot;
      </p>
      -- TLicy
    </div>
  </div>
);
