import "./why-choose-us.css";
import React from "react";

export interface WhyChooseUsProps {
  className?: string;
}

export const WhyChooseUs: React.FC<WhyChooseUsProps> = ({ className = "" }) => (
  <div className={`${className} WhyChooseUs_div1`}>
    Why Choose Us?
    <div className="WhyChooseUs_grid">
      <div className="whychoosebox">
        <div className="WhyChooseUs_div3">✔</div>
        <div className="WhyChooseUs_div2">You Decide</div>
        <p className="WhyChooseUs_p1">
          You always decide what you do (or don&apos;t do).
        </p>
      </div>
      <div className="whychoosebox">
        <div>🚀</div>
        <div className="WhyChooseUs_div2">Immediate Results</div>
        <p className="WhyChooseUs_p1">
          You always decide what you do (or don&apos;t do).
        </p>
      </div>
      <div className="whychoosebox">
        <div>📝</div>
        <div className="WhyChooseUs_div2">Shorter Contracts</div>
        <p className="WhyChooseUs_p1">
          In many cases we are able to offer contracts as short as 6 months!
        </p>
      </div>
      <div className="whychoosebox">
        <div>💰</div>
        <div className="WhyChooseUs_div2">No Startup Fees</div>
        <p className="WhyChooseUs_p1">No Startup Fees or Application Fees.</p>
      </div>
      <div className="whychoosebox">
        <div>💸</div>
        <div className="WhyChooseUs_div2">Commission Only</div>
        <p className="WhyChooseUs_p1">We earn when you earn.</p>
      </div>
      <div className="whychoosebox">
        <div>💬</div>
        <div className="WhyChooseUs_div2">Remote &amp; Location</div>
        <p className="WhyChooseUs_p1">You can work from home.</p>
      </div>
      <div className="whychoosebox">
        <div>📈</div>
        <div className="WhyChooseUs_div2">Proven Success</div>
        <p className="WhyChooseUs_p1">
          Our proven success record speaks for itself.
        </p>
      </div>
      <div className="whychoosebox">
        <div>🎓</div>
        <div className="WhyChooseUs_div2">Experience</div>
        <p className="WhyChooseUs_p1">
          Over 9 years experience in the industry.
        </p>
      </div>
    </div>
  </div>
);
