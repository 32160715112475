import "./main-page.css";
import React from "react";
import { Hero } from "../hero/hero";
import { ServicesSummary } from "../services-summary/services-summary";
import { Testimonial } from "../testimonial/testimonial";
import { WhyChooseUs } from "../why-choose-us/why-choose-us";

export interface MainPageProps {
  className?: string;
}

export const MainPage: React.FC<MainPageProps> = ({ className = "" }) => (
  <div className={`${className} MainPage_div1`}>
    <Hero />
    <div className="MainPage_div2">
      GET THE SUCCESS YOU DESERVE!
      <p className="MainPage_p1">
        At Vicarious Studio, we are redefining the adult entertainment industry
        with innovation, integrity, and passion. Our mission is to empower
        talented individuals and help them achieve their dreams by providing
        unparalleled support and opportunities. Join us on this exciting journey
        and discover a world where creativity and professionalism come together
        to create exceptional experiences. 
      </p>
    </div>
    <ServicesSummary className="MainPage_servicesSummary" />
    <Testimonial className="MainPage_testimonial" />
    <WhyChooseUs className="MainPage_whyChooseUs" />
    <div className="MainPage_div3">
      <div>What Are You Waiting For?</div>
      <div className="MainPage_div4">Apply Now And Build Your Empire!</div>
      <button className="MainPage_button1">Apply Now</button>
    </div>
  </div>
);
