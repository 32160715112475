import "./about-us-page.css";
import React from "react";

export interface AboutUsPageProps {
  className?: string;
}

export const AboutUsPage: React.FC<AboutUsPageProps> = ({ className = "" }) => (
  <div className={`${className} AboutUsPage_div1`}>
    About Us
    <hr className="AboutUsPage_hr1" />
    <div className="AboutUsPage_div2">
       Welcome to Vicarious Studio
      <p className="AboutUsPage_p1">
        At Vicarious Studio, we are dedicated to empowering and promoting the
        finest talent in the adult entertainment industry. As a premier adult
        talent agency, we pride ourselves on offering a professional,
        supportive, and innovative environment for our models to thrive and
        succeed. 
      </p>
    </div>
    <div className="AboutUsPage_div2">
      Our History
      <p className="AboutUsPage_p1">
        Founded with a passion for excellence and a commitment to integrity,
        Vicarious Studio has grown to become a trusted name in the industry. Our
        journey began with a vision to create a space where adult models could
        feel safe, respected, and celebrated for their unique talents. Today, we
        continue to uphold these values while embracing the ever-evolving
        landscape of adult entertainment. 
      </p>
    </div>
    <div className="AboutUsPage_div2">
      Mission &amp; Vision
      <p className="AboutUsPage_p1">
        Our mission is to provide unparalleled support and representation for
        our models, helping them to build successful and sustainable careers. We
        aim to be a leader in the industry by fostering a culture of
        professionalism, creativity, and mutual respect. Our vision is to set
        new standards of excellence and redefine the possibilities within adult
        entertainment. 
      </p>
    </div>
    <div className="AboutUsPage_div2">
      Meet The Team
      <p className="AboutUsPage_p1">
        Our team at Vicarious Studio is comprised of experienced professionals
        who are passionate about the adult entertainment industry. From talent
        scouts and model managers to marketing experts and content creators, we
        work collaboratively to ensure our models receive the best possible
        guidance and opportunities. 
      </p>
    </div>
    <p className="AboutUsPage_p2">
      Join us at Vicarious Studio and become part of a dynamic and thriving
      community. Together, we will achieve greatness and make a lasting mark on
      the world of adult entertainment.
    </p>
  </div>
);
