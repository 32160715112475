import "./header.css";
import React, { useState } from "react";
export interface HeaderProps {
	className?: string;
}
export const Header: React.FC<HeaderProps> = ({ className = "" }) => {
	const [isMenuOpen, setMenuOpen] = useState(false);
	const toggleMenu = () => {
		setMenuOpen(!isMenuOpen);
	};
	return (
		<div className={`${className} Header_div1 HeaderWrapper Header_div1`}>
			<div className="Header_div2 Header_div2">
				<div className="headertitlediv">Vicarious Studio</div>
				<div className="hidden md:flex HeaderMenu">
					<a href="/" className="Header_a1 Header_a1">
						Home
					</a>
					<a href="/apply" className="Header_a1">
						Apply
					</a>
					<a href="/about-us" className="Header_a1">
						About Us
					</a>
				</div>
				{/* Hamburger icon */}
				<button
					className="md:hidden flex flex-col items-center justify-center space-y-1"
					onClick={toggleMenu}
					aria-label="Toggle menu">
					<span className="w-6 h-0.5 bg-white"></span>
					<span className="w-6 h-0.5 bg-white"></span>
					<span className="w-6 h-0.5 bg-white"></span>
				</button>
			</div>
			{/* Mobile menu drawer */}
			{isMenuOpen && (
				<div className="md:hidden fixed top-0 left-0 w-full h-full bg-[#171717] bg-opacity-90 flex flex-col items-center pt-20 space-y-4 z-50">
					{/* Close X button */}
					<button
						className="absolute top-4 right-4 text-white text-2xl font-bold"
						onClick={toggleMenu}
						aria-label="Close menu">
						&times;
					</button>
					<a
						href="/"
						className="Header_a1 text-white text-lg"
						onClick={toggleMenu}>
						Home
					</a>
					<a
						href="/apply"
						className="Header_a1 text-white text-lg"
						onClick={toggleMenu}>
						Apply
					</a>
					<a
						href="/about-us"
						className="Header_a1 text-white text-lg"
						onClick={toggleMenu}>
						About Us
					</a>
				</div>
			)}
		</div>
	);
};
export default Header;
