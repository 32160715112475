import React from "react";

export interface TOSPageProps {
  className?: string;
}

export const TOSPage: React.FC<TOSPageProps> = ({ className = "" }) => (
  <div className={`p-8 max-w-3xl text-white mx-auto ${className}`}>
    <h1 className="text-2xl font-bold mb-4">Terms of Service</h1>
    <h2 className="text-xl font-semibold mb-2">Vicarious Studio Terms of Service</h2>
    <p className="mb-4">
      Welcome to Vicarious Studio. By accessing or using our website and services, you agree to comply with and be bound by the following Terms of Service. Please read these terms carefully before using our services.
    </p>

    <h3 className="text-lg font-semibold mb-2">1. Acceptance of Terms</h3>
    <p className="mb-4">
      By using our website and services, you agree to these Terms of Service and our Privacy Policy. If you do not agree with any part of these terms, you must not use our services.
    </p>

    <h3 className="text-lg font-semibold mb-2">2. Changes to Terms</h3>
    <p className="mb-4">
      Vicarious Studio reserves the right to modify or replace these Terms of Service at any time. We will notify you of any changes by posting the new terms on our website. Your continued use of the services after any such changes constitutes your acceptance of the new Terms of Service.
    </p>

    <h3 className="text-lg font-semibold mb-2">3. Eligibility</h3>
    <p className="mb-4">
      You must be at least 18 years old to use our services. By using our website, you represent and warrant that you meet this age requirement.
    </p>

    <h3 className="text-lg font-semibold mb-2">4. User Accounts</h3>
    <p className="mb-4">
      To access certain features of our services, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information as necessary. You are responsible for safeguarding your account and for all activities that occur under your account.
    </p>

    <h3 className="text-lg font-semibold mb-2">5. Prohibited Conduct</h3>
    <p className="mb-2">
      You agree not to use our services for any unlawful purpose or in any way that could harm or exploit others. Prohibited conduct includes, but is not limited to:
    </p>
    <ul className="list-disc list-inside mb-4">
      <li>Posting or transmitting any illegal, harmful, or offensive content.</li>
      <li>Impersonating another person or entity.</li>
      <li>Harassing, threatening, or abusing others.</li>
      <li>Interfering with the operation of our services.</li>
      <li>Violating any applicable laws or regulations.</li>
    </ul>

    <h3 className="text-lg font-semibold mb-2">6. Content Ownership and Usage</h3>
    <p className="mb-4">
      Vicarious Studio owns all content, including text, graphics, logos, and images, provided on our website and services. You may not reproduce, distribute, or create derivative works from our content without our express written permission.
    </p>

    <h3 className="text-lg font-semibold mb-2">7. User-Generated Content</h3>
    <p className="mb-4">
      You retain ownership of any content you submit to Vicarious Studio. By submitting content, you grant us a non-exclusive, royalty-free, worldwide license to use, display, and distribute your content in connection with our services.
    </p>

    <h3 className="text-lg font-semibold mb-2">8. Termination</h3>
    <p className="mb-4">
      We reserve the right to suspend or terminate your access to our services at any time, without notice, for any reason, including, but not limited to, violation of these Terms of Service.
    </p>

    <h3 className="text-lg font-semibold mb-2">9. Disclaimers</h3>
    <p className="mb-4">
      Our services are provided on an "as-is" and "as-available" basis. Vicarious Studio makes no warranties, express or implied, regarding the reliability, accuracy, or availability of our services. We disclaim all liability for any loss or damage resulting from your use of our services.
    </p>

    <h3 className="text-lg font-semibold mb-2">10. Limitation of Liability</h3>
    <p className="mb-4">
      To the fullest extent permitted by law, Vicarious Studio shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of our services.
    </p>

    <h3 className="text-lg font-semibold mb-2">11. Indemnification</h3>
    <p className="mb-4">
      You agree to indemnify and hold harmless Vicarious Studio, its affiliates, and its employees from any claims, damages, or expenses arising out of your use of our services or violation of these Terms of Service.
    </p>

    <h3 className="text-lg font-semibold mb-2">12. Governing Law</h3>
    <p className="mb-4">
      These Terms of Service shall be governed by and construed in accordance with the laws of the state in which Vicarious Studio is located, without regard to its conflict of law principles.
    </p>

    <p>Thank you for choosing Vicarious Studio. We look forward to serving you.</p>
  </div>
);
