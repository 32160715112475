import "./zloginpage.css";
import React, { useState, useRef } from "react";
import { useAuth } from "../../context/AuthContext";
import SpinnerComponent from "../SpinnerComponent";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { getFunctions, httpsCallable } from "firebase/functions"; 
export interface ZloginpageProps {
  className?: string;
}
export const Zloginpage: React.FC<ZloginpageProps> = ({ className = "" }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { login } = useAuth();
  const navigate = useNavigate();
  const recaptcha = useRef<ReCAPTCHA | null>(null);
  const functions = getFunctions();
  const validateCaptcha = httpsCallable(functions, 'validateCaptcha');
  const handleLogin = async (e: any) => {
    e.preventDefault();
    setError("");
    setLoading(true);
    const recaptchaValue = recaptcha.current ? recaptcha.current.getValue() : null;
    if (!recaptchaValue) {
      setError("Please complete the reCAPTCHA");
      setLoading(false);
      return;
    }
    const captchaResult = await validateCaptcha({ token: recaptchaValue }) as { data: { success: boolean } };
    if (!captchaResult.data.success) {
      setError("Invalid reCAPTCHA");
      setLoading(false);
      return;
    }
    try {
      await login(email, password);
      navigate("/zman");
    } catch {
      setError("Invalid Login");
    } finally {
      setLoading(false);
    }
  };
  if (loading) {
    return <SpinnerComponent />;
  }
  return (
  <div className={`${className} Zloginpage_div1`}>
    Login
    <div className="Zloginpage_div2">
      <input className="Zloginpage_input1 text-black" required 
            onChange={(e: any) => setEmail(e.target.value)} 
            disabled={loading}  />
      <input type="password" className="Zloginpage_input2 text-black" required 
            onChange={(e: any) => setPassword(e.target.value)}
            disabled={loading} />
            <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY || ""}  ref={recaptcha} />
            {error && <p className="mt-0 text-red-600">{error}</p>} 
      <button className="Zloginpage_button1"  disabled={loading} onClick={handleLogin}>Login</button>
    </div>
  </div>
)};
