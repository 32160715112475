import "./layout.css"
import React from "react"
import Header from "../header/header"
import { Outlet } from "react-router-dom"
import { Footer } from "../footer/footer"

export const Layout = ({ className = "" }) => (
  <div className={`${className}  bg-[#1a1a1a] min-h-screen`}>
    <Header className="Layout_header" />
    <div className="mainContainer">
      <Outlet />
    </div>
    <Footer className="Layout_footer" />
  </div>
)

export default Layout
