import { initializeApp, getApps } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getFunctions, connectFunctionsEmulator, httpsCallable } from "firebase/functions";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDBTAEyjI3b7yXtADUWkoBP73BSs9iMSRM",
  authDomain: "vicariousstudio-ffdee.firebaseapp.com",
  projectId: "vicariousstudio-ffdee",
  storageBucket: "vicariousstudio-ffdee.firebasestorage.app",
  messagingSenderId: "424933122493",
  appId: "1:424933122493:web:683795db69249228fd72d4"
};

// Initialize Firebase app only if it hasn't been initialized yet
const app = !getApps().length ? initializeApp(firebaseConfig) : getApps()[0];

const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);
// const messaging = getMessaging(app); // Remove messaging initialization

// Check if using emulator
if (process.env.REACT_APP_USE_EMULATOR === "true") {
  connectFunctionsEmulator(functions, "localhost", 5001);
}



export { auth, db, storage, functions, httpsCallable };