import React from 'react';

const SpinnerComponent = () => {
  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
      <div className="animate-spin rounded-full h-24 w-24 border-t-4 border-b-4 border-white"></div>
    </div>
  );
};

export default SpinnerComponent;
