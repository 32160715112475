import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from './context/AuthContext';
import ProtectedRoute from './context/ProtectedRoute';
import Layout from './components/layout/layout';

import HomePage from './pages/HomePage';
import { MainPage } from './components/main-page/main-page';
import { AboutUsPage } from './components/about-us-page/about-us-page';
import { ApplyPage } from './components/apply-page/apply-page';
import { PrivacyPage } from './components/privacy-page/privacy-page';
import { TOSPage } from './components/tos-page/tos-page';

import { ZmanPage } from './components/zman-page/zman-page';
import { Zloginpage } from './components/zloginpage/zloginpage';

function App() {
  return (
    <AuthProvider>
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<MainPage />} />
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/apply" element={<ApplyPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/tos" element={<TOSPage />} />
          <Route path="/login" element={<Zloginpage />} />
          {/* Arcane Routes */}
          <Route element={<ProtectedRoute />} >
            <Route path="/zman" element={<ZmanPage />} />
          </Route>
        </Route>
      </Routes>
    </Router>
    </AuthProvider>
  );
}

export default App;
