import "./footer.css";
import React from "react";

export interface FooterProps {
  className?: string;
}

export const Footer: React.FC<FooterProps> = ({ className = "" }) => (
  <div className={`${className} Footer_div1`}>
    <div>
      <a href="/privacy" className="Footer_a1">
        Privacy Policy
      </a>
       | <a href="/tos">Terms Of Service</a>
    </div>
    <div>© 2024 Vicarious Studio. All rights reserved. </div>
  </div>
);
