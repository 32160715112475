import React from "react";

export interface PrivacyPageProps {
  className?: string;
}

export const PrivacyPage: React.FC<PrivacyPageProps> = ({ className = "" }) => (
  <div className={`p-8 max-w-3xl text-white mx-auto ${className}`}>
    <h1 className="text-2xl font-bold mb-4 text-center">Privacy Policy</h1>
    <h2 className="text-xl font-semibold mb-2">Vicarious Studio Privacy Policy</h2>
    <p className="mb-4">
      At Vicarious Studio, we respect your privacy and are committed to protecting your personal information.
      This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website or use our services.
    </p>

    <h3 className="text-lg font-semibold mb-2">Information We Collect</h3>
    <p className="mb-2">We may collect personal information that you voluntarily provide to us when you contact us, register on our site, subscribe to our newsletter, fill out a form, or engage with our services. This information may include:</p>
    <ul className="list-disc list-inside mb-4">
      <li>Name</li>
      <li>Email Address</li>
      <li>Phone Number</li>
      <li>Mailing Address</li>
      <li>Social Media Profiles</li>
      <li>Any other information you provide</li>
    </ul>
    <p className="mb-2">We also collect non-personal information automatically as you navigate through our site. This may include:</p>
    <ul className="list-disc list-inside mb-4">
      <li>IP Address</li>
      <li>Browser Type</li>
      <li>Operating System</li>
      <li>Access Times</li>
      <li>Referring Website Addresses</li>
    </ul>

    <h3 className="text-lg font-semibold mb-2">How We Use Your Information</h3>
    <ul className="list-disc list-inside mb-4">
      <li>To provide, operate, and maintain our website and services</li>
      <li>To improve, personalize, and expand our website and services</li>
      <li>To understand and analyze how you use our website and services</li>
      <li>To develop new products, services, features, and functionality</li>
      <li>To communicate with you, including customer service, updates, and promotional purposes</li>
      <li>To process your transactions and manage your orders</li>
      <li>To send you newsletters, marketing communications, and other information you have opted to receive</li>
      <li>To protect against fraudulent or unauthorized transactions and protect our rights</li>
    </ul>

    <h3 className="text-lg font-semibold mb-2">Sharing Your Information</h3>
    <p className="mb-2">
      We do not sell, trade, or otherwise transfer your personal information to outside parties without your consent, except as described below:
    </p>
    <ul className="list-disc list-inside mb-4">
      <li>Service Providers: We may share your information with third-party service providers who perform services on our behalf, such as payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.</li>
      <li>Legal Requirements: We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a court or government agency).</li>
      <li>Business Transfers: In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of that transaction.</li>
    </ul>

    <h3 className="text-lg font-semibold mb-2">Security of Your Information</h3>
    <p className="mb-4">
      We use administrative, technical, and physical security measures to help protect your personal information. While we strive to protect your personal information, no method of transmission over the internet or method of electronic storage is completely secure, and we cannot guarantee its absolute security.
    </p>

    <h3 className="text-lg font-semibold mb-2">Your Data Protection Rights</h3>
    <p className="mb-2">Depending on your location, you may have the following rights regarding your personal information:</p>
    <ul className="list-disc list-inside mb-4">
      <li>The right to access – You have the right to request copies of your personal information.</li>
      <li>The right to rectification – You have the right to request that we correct any information you believe is inaccurate or complete information you believe is incomplete.</li>
      <li>The right to erasure – You have the right to request that we erase your personal information, under certain conditions.</li>
      <li>The right to restrict processing – You have the right to request that we restrict the processing of your personal information, under certain conditions.</li>
      <li>The right to object to processing – You have the right to object to our processing of your personal information, under certain conditions.</li>
      <li>The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</li>
    </ul>

    <h3 className="text-lg font-semibold mb-2">Changes to This Privacy Policy</h3>
    <p>
      We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
    </p>
  </div>
);
