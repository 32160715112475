import React, { useState, useRef } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import ReCAPTCHA from "react-google-recaptcha";
import SpinnerComponent from "../SpinnerComponent";
export interface ApplyPageProps {
	className?: string;
}
export const ApplyPage: React.FC<ApplyPageProps> = ({ className = "" }) => {
	const functions = getFunctions();
  const recaptcha = useRef<ReCAPTCHA | null>(null);
  const [isDone, setIsDone] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
	// State hooks for each form field
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [dob, setDob] = useState("");
	const [city, setCity] = useState("");
	const [state, setState] = useState("");
	const [instagram, setInstagram] = useState("");
	const [facePhoto, setFacePhoto] = useState<File | null>(null);
	const [bodyPhoto, setBodyPhoto] = useState<File | null>(null);
  const fileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });
};
const handleSubmit = async (event: React.FormEvent) => {
  event.preventDefault();
  setLoading(true);
  setError("");
  const recaptchaValue = recaptcha.current ? recaptcha.current.getValue() : null;
  if (!recaptchaValue) {
      setError("Please complete the reCAPTCHA");
      setLoading(false);
      return;
  }
  const validateCaptcha = httpsCallable(functions, 'validateCaptcha');
  const captchaResult = await validateCaptcha({ token: recaptchaValue }) as { data: { success: boolean } };
    if (!captchaResult.data.success) {
      setError("Invalid reCAPTCHA");
      setLoading(false);
      return;
    }
  const sendApplication = httpsCallable(functions, "sendApplication");
  const facePhotoBase64 = facePhoto ? await fileToBase64(facePhoto) : null;
  const bodyPhotoBase64 = bodyPhoto ? await fileToBase64(bodyPhoto) : null;
  const formData = {
      firstName,
      lastName,
      email,
      phone,
      dob,
      city,
      state,
      instagram,
      facePhoto: facePhotoBase64,
      bodyPhoto: bodyPhotoBase64,
  };
  try {
      const result = await sendApplication(formData);
      
      setIsDone(true);
  } catch (error) {
      setError("Error, please try again later");
  } finally {
      setLoading(false);
  }
};
if(loading) {
  return <SpinnerComponent/>
}
if(isDone) {
  return <p className="text-white text-center">Thank you for applying! We will get back to you soon.<br/><br/><a href="/">Back to homepage</a></p> 
}
	return (
		<div
			className={`${className} p-6 max-w-lg mx-auto bg-[#2c2c2c] text-white rounded-lg shadow-md`}>
			<h2 className="text-2xl font-bold mb-4 text-center">Apply Now</h2>
			<form onSubmit={handleSubmit} className="space-y-4">
				<div className="flex flex-col">
					<label className="text-sm font-medium mb-1">First Name</label>
					<input
						type="text"
						value={firstName}
						onChange={(e) => setFirstName(e.target.value)}
						className="p-2 border rounded-md text-black"
						required
					/>
				</div>
				<div className="flex flex-col">
					<label className="text-sm font-medium mb-1">Last Name</label>
					<input
						type="text"
						value={lastName}
						onChange={(e) => setLastName(e.target.value)}
						className="p-2 border rounded-md text-black"
						required
					/>
				</div>
				<div className="flex flex-col">
					<label className="text-sm font-medium mb-1">Email</label>
					<input
						type="email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						className="p-2 border rounded-md text-black"
						required
					/>
				</div>
				<div className="flex flex-col">
					<label className="text-sm font-medium mb-1">Phone</label>
					<input
						type="tel"
						value={phone}
						onChange={(e) => setPhone(e.target.value)}
						className="p-2 border rounded-md text-black"
						required
					/>
				</div>
				<div className="flex flex-col">
					<label className="text-sm font-medium mb-1">Date of Birth</label>
					<input
						type="date"
						value={dob}
						onChange={(e) => setDob(e.target.value)}
						className="p-2 border rounded-md text-black"
						required
					/>
				</div>
				<div className="flex flex-col">
					<label className="text-sm font-medium mb-1">City</label>
					<input
						type="text"
						value={city}
						onChange={(e) => setCity(e.target.value)}
						className="p-2 border rounded-md text-black"
						required
					/>
				</div>
				<div className="flex flex-col">
					<label className="text-sm font-medium mb-1">State</label>
					<select
						value={state}
						onChange={(e) => setState(e.target.value)}
						className="p-2 border rounded-md text-black"
						required>
						<option value="">Select a state</option>
						<option value="AL">Alabama</option>
						<option value="AK">Alaska</option>
						<option value="AZ">Arizona</option>
						<option value="AR">Arkansas</option>
						<option value="CA">California</option>
						<option value="CO">Colorado</option>
						<option value="CT">Connecticut</option>
						<option value="DE">Delaware</option>
						<option value="FL">Florida</option>
						<option value="GA">Georgia</option>
						<option value="HI">Hawaii</option>
						<option value="ID">Idaho</option>
						<option value="IL">Illinois</option>
						<option value="IN">Indiana</option>
						<option value="IA">Iowa</option>
						<option value="KS">Kansas</option>
						<option value="KY">Kentucky</option>
						<option value="LA">Louisiana</option>
						<option value="ME">Maine</option>
						<option value="MD">Maryland</option>
						<option value="MA">Massachusetts</option>
						<option value="MI">Michigan</option>
						<option value="MN">Minnesota</option>
						<option value="MS">Mississippi</option>
						<option value="MO">Missouri</option>
						<option value="MT">Montana</option>
						<option value="NE">Nebraska</option>
						<option value="NV">Nevada</option>
						<option value="NH">New Hampshire</option>
						<option value="NJ">New Jersey</option>
						<option value="NM">New Mexico</option>
						<option value="NY">New York</option>
						<option value="NC">North Carolina</option>
						<option value="ND">North Dakota</option>
						<option value="OH">Ohio</option>
						<option value="OK">Oklahoma</option>
						<option value="OR">Oregon</option>
						<option value="PA">Pennsylvania</option>
						<option value="RI">Rhode Island</option>
						<option value="SC">South Carolina</option>
						<option value="SD">South Dakota</option>
						<option value="TN">Tennessee</option>
						<option value="TX">Texas</option>
						<option value="UT">Utah</option>
						<option value="VT">Vermont</option>
						<option value="VA">Virginia</option>
						<option value="WA">Washington</option>
						<option value="WV">West Virginia</option>
						<option value="WI">Wisconsin</option>
						<option value="WY">Wyoming</option>
					</select>
				</div>
				<div className="flex flex-col">
					<label className="text-sm font-medium mb-1">Instagram</label>
					<input
						type="text"
						value={instagram}
						onChange={(e) => setInstagram(e.target.value)}
						className="p-2 border rounded-md text-black"
					/>
				</div>
				<div className="flex flex-col">
					<label className="text-sm font-medium mb-1">Face Photo</label>
					<input
						type="file"
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							if (e.target.files) {
								setFacePhoto(e.target.files[0]);
							}
						}}
						className="p-2 border rounded-md"
						required
					/>
				</div>
				<div className="flex flex-col">
					<label className="text-sm font-medium mb-1">Full Body Photo</label>
					<input
						type="file"
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							if (e.target.files) {
								setBodyPhoto(e.target.files[0]);
							}
						}}
						className="p-2 border rounded-md"
						required
					/>
				</div>
				<div className="flex justify-center mt-4">
					<div className="w-full bg-gray-200 rounded-md flex items-center justify-center">
          <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY || ""}  ref={recaptcha} />          
					</div>
          
				</div>
        <div className="flex justify-center mt-4 bg-gray-200">
        {error && <p className="mt-0 text-red-600">{error}</p>} 
        </div>
				<button
					type="submit"
					className="w-full bg-[#800020] text-white py-2 rounded-md font-semibold hover:bg-blue-600 mt-4">
					Submit
				</button>
			</form>
		</div>
	);
};
