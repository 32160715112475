import React, { useState, useEffect } from "react";
import SpinnerComponent from "../SpinnerComponent";
import { getFunctions, httpsCallable } from "firebase/functions";
import axios from "axios";
export interface ZmanPageProps {
  className?: string;
}
export const ZmanPage: React.FC<ZmanPageProps> = ({ className = "" }) => {
  const [loading, setLoading] = useState(true);
  const [models, setModels] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState("Current Period");
  const [grandTotal, setGrandTotal] = useState(0);
  const [studioTotal, setStudioTotal] = useState(0);
  const [modelTotal, setModelTotal] = useState(0);
  useEffect(() => {
    const functions = getFunctions();
    const getModels = httpsCallable(functions, 'getModels');
    getModels({ period: "Current Period" })
      .then((result) => {
        const data = result.data as { models: any[] }; 
        setModels(data.models);
        // Calculate grand total, studio total, and model total
        const total = data.models.reduce((acc, model) => acc + ((model.modelInfo.totalEarnings || 0) * 0.05), 0);
        const studio = data.models.reduce((acc, model) => acc + ((model.modelInfo.totalEarnings || 0) * 0.05 * 0.30), 0);
        const model = data.models.reduce((acc, model) => acc + ((model.modelInfo.totalEarnings || 0) * 0.05 * 0.70), 0);
        setGrandTotal(total);
        setStudioTotal(studio);
        setModelTotal(model);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []); 
  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
    setLoading(true);
    const functions = getFunctions();
    const getModels = httpsCallable(functions, 'getModels');
    getModels({ period: tab })
      .then((result) => {
        const data = result.data as { models: any[] }; 
        setModels(data.models);
        // Calculate grand total, studio total, and model total
        const total = data.models.reduce((acc, model) => acc + ((model.modelInfo.totalEarnings || 0) * 0.05), 0);
        const studio = data.models.reduce((acc, model) => acc + ((model.modelInfo.totalEarnings || 0) * 0.05 * 0.30), 0);
        const model = data.models.reduce((acc, model) => acc + ((model.modelInfo.totalEarnings || 0) * 0.05 * 0.70), 0);
        setGrandTotal(total);
        setStudioTotal(studio);
        setModelTotal(model);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  if (loading) {
    return <SpinnerComponent />;
  }
  return (
    <div className={className}>
      {/* Tab Bar styled as Pills */}
      <div className="flex space-x-2 mb-4">
        {["Current Period", "Last Payment", "24 Hours"].map((tab) => (
          <button
            key={tab}
            onClick={() => handleTabClick(tab)}
            className={`py-2 px-4 rounded-full font-semibold transition-colors duration-200 ${
              activeTab === tab
                ? "bg-[#800020] text-white"
                : "border border-[#800020] text-white hover:bg-[#800020] hover:text-white"
            }`}
          >
            {tab}
          </button>
        ))}
      </div>
      {/* Tab Content */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left text-gray-600 font-medium">
                ID
              </th>
              <th className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left text-gray-600 font-medium">
                Total
              </th>
              <th className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left text-gray-600 font-medium">
                Studio
              </th>
              <th className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left text-gray-600 font-medium">
                Model
              </th>
              {/* Add more headers as needed */}
            </tr>
          </thead>
          <tbody>
            {models.map((model, index) => (
              <tr
                key={model.id}
                className={index % 2 === 0 ? "bg-gray-50" : "bg-white"}
              >
                <td className="py-2 px-4 border-b border-gray-200">{model.id}</td>
                <td className="py-2 px-4 border-b border-gray-200">
                  {"$" + (model.modelInfo.totalEarnings * 0.05).toFixed(2) || "0"}
                </td>
                <td className="py-2 px-4 border-b border-gray-200">
                  {"$" + (model.modelInfo.totalEarnings * 0.05 * .30).toFixed(2) || "0"}
                </td>
                <td className="py-2 px-4 border-b border-gray-200">
                  {"$" + (model.modelInfo.totalEarnings * 0.05 * .70).toFixed(2) || "0"}
                </td>
                {/* Add more cells as needed */}
              </tr>
            ))}
            <tr>
              <td className="py-2 px-4 border-b border-gray-200 font-bold">Grand Total:</td>
              <td className="py-2 px-4 border-b border-gray-200 font-bold">${grandTotal.toFixed(2)}</td>
              <td className="py-2 px-4 border-b border-gray-200 font-bold">${studioTotal.toFixed(2)}</td>
              <td className="py-2 px-4 border-b border-gray-200 font-bold">${modelTotal.toFixed(2)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
